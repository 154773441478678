import React from 'react';
import PrimaryButton from './PrimaryButton';
import CoverImage from '../assets/cover-image.png';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/logo/logoDark.svg';
import ReactLoading from 'react-loading';

const Cover = ({data, isOK, isLoading}) => {
    const { SongName, ArtistName, onlineSongName, onlineArtistName, CoverImageUrl } = data;

    const isMobile = useMediaQuery({ maxWidth: 768 });
    const isSmall = useMediaQuery({ maxHeight: 677 });

    const containerStyle = {
        flex: 1,
        height: '95vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0rem',
    };

    const brandContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    }

    const headerStyle = {
        height: "50vh",
        padding: '0 0 2rem 0',
    };

    const descriptionStyle = {
        fontSize: isSmall ? '1rem': '1.3rem',
        marginBottom: '1rem',
        textAlign: 'center',
        marginTop: '0.5rem',
        color: '#FFFFFF',
    };



    const buttonStyle = {
        fontSize: '1rem',
        padding: '0 2rem',
        borderRadius: '5px',
        cursor: 'pointer',
    };
    const spacerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50vw',
    };
    const horizontalStyle = {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'center',
    };
//CoverImageUrl
    return (
        <div style={containerStyle}>
            <div style={brandContainerStyle} >
                <img style={headerStyle} src={CoverImageUrl}/>
                <div style={horizontalStyle}>
                    <div style={{display: 'flex', flex: 'row '}}>
                        <div style={spacerStyle}>
                            <p style={descriptionStyle}>Local</p>
                            <p style={descriptionStyle}>{SongName}<br/>{ArtistName}</p>
                            <PrimaryButton size={2} color={"#24aa3d"} hover={"#34c759"} onClick={() => isOK(true)} style={buttonStyle}>
                            {isLoading
                                ?
                                <ReactLoading type='spinningBubbles' color={"#FFFFFF"} height={"110%"} width={"110%"} />
                                :
                                <>Right</>
                            }
                            </PrimaryButton>
                        </div>
                        
                        <div style={spacerStyle}>
                            <p style={descriptionStyle}>Online</p>
                            <p style={descriptionStyle}>{onlineSongName}<br/>{onlineArtistName}</p>
                            <PrimaryButton size={2} color={"#d72015"} hover={"#FF3B30"} onClick={() => isOK(false)} style={buttonStyle}>
                            {isLoading
                                ?
                                <ReactLoading type='spinningBubbles' color={"#FFFFFF"} height={"110%"} width={"110%"} />
                                :
                                <>Wrong</>
                            }  
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cover;
