import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from "@emotion/styled";
import '../styles/App.css';
import Cover from '../components/Cover';
import musicData from '../data/modified_data.json';
import base64 from 'base-64';
import PrimaryButton from '../components/PrimaryButton';
import IntegerInput from '../components/IntegerInput';

const clientId = process.env.REACT_APP_CLIENT_ID; 
const clientSecret = process.env.REACT_APP_CLIENT_SECRET; 
const baseUri = "https://api.spotify.com/v1/";

let accessTokenCache;

async function getToken() {
  if (accessTokenCache) {
    return accessTokenCache;
  }

  const url = "https://accounts.spotify.com/api/token";
  const headers = {
    'Authorization': 'Basic ' + (base64.encode(clientId + ':' + clientSecret).toString('base64'))
  };

  const data = "grant_type=client_credentials";

  try {
    const response = await axios.post(url, data, { headers });
    accessTokenCache = response.data.access_token;
    return accessTokenCache;
  } catch (error) {
    console.error('Error getting access token:', error.message);
    throw error;
  }
}

function App() {
  const [isOK, setIsOK] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [firstOpen, setFirstOpen] = useState(true);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [songData, setSongData] = useState({
    SongName: "",
    ArtistName: "",
    onlineSongName: "",
    onlineArtistName: "",
    CoverImageUrl: "",
    ids:{
      "trackId": "",
      "albumId": "",
      "artistId": ""
    },
    index: null
  });

  const [allSongData, setAllSongData] = useState([]);

  const mainStyle = {
    background: 'linear-gradient(360deg, #16182c 0%, #16182c 100%)',
    fontFamily: 'Inclusive Sans',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const musicValues = Object.values(musicData);

  const updateState = useCallback(async () => {
    if(isOK !== null && firstOpen === false){
      if (currentIndex < musicValues.length ) {
        setIsLoading(true);
        const music = musicValues[currentIndex];

        try {
          const accessToken = await getToken();

          const response = await axios.get(`${baseUri}tracks/${music.ids.trackId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const onlineMusicData = response.data;

          // Set online data in state
          const onlineName = onlineMusicData.name
          const onlineArtist = onlineMusicData.artists[0].name

          if (onlineName.trim().toLowerCase() === music.song.trim().toLowerCase() && onlineArtist.trim().toLowerCase() === music.artist.trim().toLowerCase()) {
            setIsOK(true);
          }else{
            setSongData({
              SongName: music.song,
              ArtistName: music.artist,
              onlineSongName: onlineName,
              onlineArtistName: onlineArtist,
              CoverImageUrl: onlineMusicData.album.images[0].url,
              ids: music.ids,
              index: currentIndex
            });
            if(isOK === false){
              setAllSongData(prevData => [...prevData, songData]);
            }
          }

        } catch (error) {
          console.error('Error fetching Spotify data:', error);
        }

        setIsOK((prevIsOK) => {
          setCurrentIndex(currentIndex + 1);
          return null;
        });
        setIsLoading(false);

      } else {
        setCurrentIndex(0);
      }
    }
  }, [currentIndex, isOK]);

  function downloadData() {
    // Create a blob of the data
    const blob = new Blob([JSON.stringify(allSongData, null, 2)], {type : 'application/json'});
    // Create a link element
    const link = document.createElement("a");
    // Set the download attribute with a value
    link.download = "wrongSongs.json";
    // Create a URL to the blob
    link.href = URL.createObjectURL(blob);
    // Programmatically click the link to trigger the download
    link.click();
    // Release the reference to the blob
    URL.revokeObjectURL(link.href);
  }

  function setOpen() {
    setFirstOpen(false);
    setIsOK(true);
  }

  useEffect(() => {
    console.log(currentIndex)
    requestAnimationFrame(updateState);
  }, [currentIndex, isOK, updateState]);

  return (
    <div style={mainStyle}>
      {firstOpen?
          <div style={{ display: 'flex', flex: 1, display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
            <IntegerInput size={2} defaultValue={0} setValue={setCurrentIndex} style={{margin: "5rem 0"}}/>
            <PrimaryButton size={3} onClick={setOpen}>Start at index {currentIndex}</PrimaryButton>
          </div>
        :
        <>
          <Cover data={songData} isOK={setIsOK} isLoading={isLoading} />
          <PrimaryButton size={2} style={{margin: "2rem 0"}} onClick={downloadData}>Download Data</PrimaryButton>
        </>
      }
      
    </div>
  );
}

export default App;
