import React, { useState } from 'react';

const PrimaryButton = ({ children, onClick, size, href, style, color, hover, border }) => {
  const [isHovered, setIsHovered] = useState(false)

  if (size === undefined) size = 1;
  if (border === undefined) border = "#00000000";
  if (color === "none") color = "#00000000";
  if (hover === "none") hover = "#00000000";

  const buttonStyle = {
    padding: `${size * 0.5}rem ${size * 0.75}rem`,
    background: color !== undefined ? color : 'linear-gradient(30deg, rgba(255, 165, 40, 0.83) 0%, rgba(214, 149, 30, 0.83) 20%, rgba(214, 149, 30, 0.83) 60%, rgba(255, 165, 40, 0.83) 100%)',
    borderRadius: `${size * 0.8}rem`,
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    transformOrigin: 'center',
    cursor: 'pointer',
    boxShadow: 'none',
    color: "#FFFFFF",
    boxShadow: `inset -2.8px 0 0 ${border}, inset 0 -2.8px 0 ${border}, inset 2.8px 0 0 ${border}, inset 0 2.8px 0 ${border}`,
    outline: 'none',
    fontSize: `${size}rem`,
    border: 'none',
    minWidth: `${size * 2.6}rem`,
  };

  const hoverButtonStyle = {
    background: hover !== undefined ? hover :'linear-gradient(-30deg, rgba(225, 184, 30, 1) 0%, rgba(243, 184, 40, 0.95)  100%)',
    transform: 'scale(1.1)',
    cursor: 'pointer',
    boxShadow: 'outset 0 0 10px rgba(255, 255, 200, 0.2)',
  };

  return (
    <a href={href} style={{ ...style, textDecoration: 'none' }}>
        <button
        style={{ ...buttonStyle, ...(isHovered ? hoverButtonStyle : {}) }}
        onMouseEnter={() => {setIsHovered(true)}}
        onMouseLeave={() => {setIsHovered(false)}}
        onClick={onClick}
        >
      {children}
    </button>
    </a>
  );
};

export default PrimaryButton;