import React, { useState, useEffect } from 'react';

const IntegerInput = ({ setValue, defaultValue = '', size, style, color, border }) => {
  const [value, setValueState] = useState(defaultValue)
  const [isError, setIsError] = useState(false)

  if (size === undefined) size = 1;
  if (border === undefined) border = "#00000000";
  if (color === "none") color = "#00000000";

  const inputStyle = {
    padding: `${size * 0.5}rem ${size * 0.75}rem`,
    background: color !== undefined ? color : '#FFFFFF',
    borderRadius: `${size * 0.8}rem`,
    color: "#000000",
    boxShadow: `inset -2.8px 0 0 ${border}, inset 0 -2.8px 0 ${border}, inset 2.8px 0 0 ${border}, inset 0 2.8px 0 ${border}`,
    outline: 'none',
    fontSize: `${size}rem`,
    border: 'none',
    minWidth: `${size * 2.2}rem`,
  };

  const errorStyle = {
    ...inputStyle,
    borderColor: 'red',
  };

  useEffect(() => {
    setIsError(value !== '' && !/^\\d+$/.test(value));
  }, [value]);

  const handleChange = (e) => {
    const val = parseInt(e.target.value);
    setValueState(val);
    setValue(val);
  }
  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      style={{ ...(isError ? errorStyle : inputStyle), ...style }}
    />
  );
};

export default IntegerInput;
